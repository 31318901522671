<script>
import { fetchPageBuilder, OptionsComponent } from '@drapejs/core';
import { setDefaultMeta } from '@/composables/useMeta';
import { sales } from '@motillo/drapejs-litium';
import * as vingaSales from '../../connectors/litium/sales';

import ReceiptOrderInfo from '../components/Receipt/ReceiptOrderInfo';
import ReceiptOrderSummary from '../components/Receipt/ReceiptOrderSummary';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import useContext from '@/composables/useContext';

export default {
  extends: OptionsComponent,
  components: {
    ReceiptOrderInfo,
    ReceiptOrderSummary,
    LoadingSpinner
  },
  data: () => ({
    loaded: false,
    order: null
  }),
  computed: {
    caption() {
      return 'Your order has been placed';
    },
    loadingCaption() {
      return 'Loading...';
    }
  },
  setup() {
    const { page } = useContext();
    return { page };
  },
  methods: {
    async getOrder() {
      const request = {
        ...this.buildRequest(),
        orderId: this.$route.query.order_reference
      };
      request.url = `${request.protocol}//${request.host}${request.path}`;
      const result = await this.$invoke(sales.getOrder, request);
      return result;
    },
    calculateTotals(order) {
      var totalPrints = 0;
      order.rows?.forEach((row) => {
        totalPrints += row?.printRow?.unitPrice * row?.printRow?.quantity || 0;
        row?.addOns?.forEach((addon) => {
          totalPrints += addon?.unitPrice || 0;
        });
      });
      order.totalPrints = totalPrints;

      var totalAddons = 0;
      order.rows?.forEach((row) => {
        row?.giftCardInfo?.addons?.forEach((addon) => {
          totalAddons += addon?.price * addon?.quantity || 0;
        });
      });
      order.totalAddons = totalAddons;
      order.totalProducts = order.rows?.reduce(
        (rowSum, row) => 
        rowSum 
        + (row?.totalPrice ?? 0),
        0) ;

      var totalGiftCardShipping = 0;
      order.rows?.forEach((row) => {
        totalGiftCardShipping +=
          row.giftCardInfo?.giftCardShipping?.price *
            row.giftCardInfo?.giftCardShipping?.quantity || 0;
      });
      order.totalGiftCardShipping = totalGiftCardShipping;
      order.sumWithoutVat = order.grandTotal - order.totalVat;
    },
    pushPurchaseEvent() {
      if (typeof dataLayer === 'undefined') {
        return;
      }

      if (
        (typeof sessionStorage !== 'undefined' &&
          sessionStorage.getItem('dataLayerOrderId') ==
            this.$route.query.order_reference) ||
        this.order?.additionalOrderInfo?.postPurchaseEventSentToGtm
      ) {
        return;
      }

      const items = [];
      for (let i = 0; i < this.order.rows.length; i++) {
        const row = this.order.rows[i];
        const percentageOfTotal = this.order.totalOrderRow
          ? row.totalPrice / this.order.totalOrderRow
          : 0;

        const item = {
          item_id: row.articleNumber,
          item_name: row.googleAnalyticsName,
          discount: this.order.totalDiscount * percentageOfTotal,
          price: row.unitListPrice,
          quantity: row.quantity
        };

        const categoryPath = row.googleAnalyticsCategoryPath || [];
        for (let j = 0; j < Math.min(5, categoryPath.length); j++) {
          if (j == 0) {
            item.item_category = categoryPath[j];
          } else {
            item[`item_category${j + 1}`] = categoryPath[j];
          }
        }

        items.push(item);
      }

      dataLayer.push({
        event: 'purchase',
        currency: this.$cart?.currency?.id || '',
        transaction_id: this.order.externalOrderId,
        value: this.order.grandTotal,
        shipping: this.order.totalDeliveryCost,
        tax: this.order.totalVat,
        items
      });

      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(
          'dataLayerOrderId',
          this.$route.query.order_reference
        );
      }

      if (this.order) {
        const setPostPurchaseRequest = this.buildRequest();
        setPostPurchaseRequest.orderId = this.$route.query.order_reference;
        this.$invoke(
          vingaSales.commands.setSentPostPurchaseGtmEvent,
          setPostPurchaseRequest
        );
      }
    },
    buildRequest() {
      return fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query
        },
        ''
      );
    }
  },
  async mounted() {
    setDefaultMeta();
    try {
      const order = await this.getOrder();

      if (JSON.stringify(order) == '{}') {
        this.error =
          'Failed to retrieve data about the order. Please try again later or check under my pages';
      } else {
        this.calculateTotals(order);
        this.order = order;
      }
    } catch (ex) {
      this.error = 'Something bad happened. Please try again later';
    } finally {
      this.loaded = true;

      this.pushPurchaseEvent();
    }
  }
};
</script>

<template>
  <div class="receipt">
    <div v-if="loaded && order">
      <div class="receipt-page__header">
        <blocks-repeater
          v-if="page.blocks && page.blocks.Header"
          :blocks="page.blocks.Header"
        ></blocks-repeater>
      </div>
      <div class="receipt__content">
        <div class="receipt__section">
          <div class="receipt-page__order" v-if="order">
            <receipt-order-info :order="order" />
            <receipt-order-summary :order="order" />
          </div>
          <div class="receipt-page__error" v-else>
            {{ error }}
          </div>
        </div>
      </div>
      <div class="receipt-page__footer">
        <blocks-repeater
          v-if="page.blocks && page.blocks.Footer"
          :blocks="page.blocks.Footer"
        ></blocks-repeater>
      </div>
    </div>
    <div v-else>
      <loading-spinner class="receipt-page__loader" :text="loadingCaption" />
    </div>
  </div>
</template>

<style>
.receipt {
  position: relative;
  margin: 0 auto;
  max-width: 850px;
}

.receipt-page__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.receipt-page__title {
  color: var(--color-feedback-success);
}

.receipt-page__icon {
  border-radius: 50%;
  border: 2px solid var(--color-feedback-success);
  width: 70px;
  height: 70px;
  padding: 15px;
}

.receipt__content {
  width: 100%;
}

.receipt__section {
  margin: 0 auto;
  width: 100%;
  padding-bottom: 1.75rem;
}

.receipt-page__title {
  padding: 2rem 3rem;
  font-size: 16px;
}

.receipt-page__order {
  display: flex;
  flex-direction: column;
}

.receipt-page__order > div {
  width: 100%;
}

.receipt-page__loader {
  padding: 2rem 3rem;
}

.receipt-page__error {
  text-align: center;
  font-weight: 700;
  padding: 2rem 3rem;
  color: var(--color-feedback-error);
  font-size: 17px;
}

@media (--tabletAndDesktop) {
  .receipt-page__title {
    padding: 2rem;
    font-size: 22px;
  }

  .receipt__content {
    margin: 2rem 0;
  }

  .receipt-page__order {
    flex-direction: row;
  }

  .receipt-page__order > div {
    width: 50%;
  }
}
</style>
